import React from "react";

type Props = {
	className?: string;
	onClick: () => void;
};

const OutlinedButton: React.FC<Props> = ({ children, onClick, className }) => {
	return (
		<button
			className={"border border-zinc-300 rounded px-3 py-2 " + className}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default OutlinedButton;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logoutUser } from "../../store/slices/authSlice";
import Container from "../../commons/Container";
import { setAuthToken } from "../../utils";
import mainLogo from "../../assets/main-logo.png";
import { Button } from "../Buttons";

const Navbar: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { isAuthenticated, user } = useAppSelector((state) => state.auth);

	const handleLogout = () => {
		localStorage.removeItem("jwtToken");
		// remove auth header for future requests
		setAuthToken(null);
		dispatch(logoutUser());
		navigate("/");
	};

	return (
		<nav className="border-b">
			<Container>
				<div className="h-14 md:h-16 flex items-center justify-between">
					<div className="logo">
						<Link to={isAuthenticated ? "/home" : "/"}>
							<img
								src={mainLogo}
								alt="ParkMate"
								className="w-28 md:w-32"
							/>
						</Link>
					</div>
					<div className="flex items-center gap-4 md:gap-5">
						<Link
							to={isAuthenticated ? "/home" : "/"}
							className="text-base"
						>
							Home
						</Link>
						{isAuthenticated && (
							<Button
								base
								onClick={handleLogout}
								className="bg-gray-600"
							>
								Logout
							</Button>
						)}
					</div>
				</div>
			</Container>
		</nav>
	);
};

export default Navbar;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Fuse from "fuse.js";
import { useAppSelector } from "../../store/hooks";
import { Booking } from "../../components/BookingCard/types";
import BookingsList from "../../components/BookingsList/BookingsList";
import FiltersMenu from "../../components/FiltersMenu/FiltersMenu";
import FiltersNav from "../../components/FiltersNav/FiltersNav";
import RightSidebar from "../../components/RightSidebar/RightSidebar";
import { API_URL } from "../../constants";
import Stats from "../../components/Stats/Stats";

type BookingParams = {
  area?: string;
  source?: string;
  today?: boolean;
  yesterday?: boolean;
  startDate?: string;
  endDate?: string;
};

type IFilters = {
  today?: boolean;
  yesterday?: boolean;
  startDate?: string;
  endDate?: string;
  forDate?: string;
};

export enum FiltersMenuModes {
  Filters,
  Download,
}

const Home: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [initialBookings, setInitialBookings] = useState<Booking[]>([]);
  const [bookings, setBookings] = useState<Booking[]>([]);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<{ hasError: boolean; message: any }>({
    hasError: false,
    message: "",
  });

  const [sidebar, setSidebar] = useState<{
    title: string;
    isVisible: boolean;
    component: string;
  }>({
    title: "",
    isVisible: false,
    component: "",
  });

  const initialFilters: IFilters = {
    today: true,
    yesterday: false,
    startDate: "",
    endDate: "",
    forDate: "",
  };
  const [tempFilters, setTempFilters] = useState<IFilters>(initialFilters);
  const [chosenFilters, setChosenFilters] = useState<IFilters>(initialFilters);
  const [filtersMenuMode, setFiltersMenuMode] = useState<FiltersMenuModes>(
    FiltersMenuModes.Filters
  );

  const fetchBookings = async (params: BookingParams = chosenFilters) => {
    try {
      setIsFetching(true);
      setError({
        hasError: false,
        message: "",
      });
      const res = await axios.get(`${API_URL}/api/orders`, {
        params: {
          ...params,
          area: user?.alias,
        },
      });

      const { orders } = res.data;

      setInitialBookings(orders);
      setBookings(orders);
      setIsFetching(false);
      setError({
        hasError: false,
        message: "",
      });
    } catch (error: any) {
      console.log({ error });
      setIsFetching(false);
      setError({
        hasError: true,
        message: error?.response?.message || "Server Error Occured. Try Again",
      });
    }
  };

  useEffect(() => {
    const appliedFiltersStr: string | null =
      localStorage.getItem("appliedFilters");
    if (appliedFiltersStr) {
      setTempFilters(JSON.parse(appliedFiltersStr));
      setChosenFilters(JSON.parse(appliedFiltersStr));
    }
  }, []);

  useEffect(() => {
    fetchBookings();
  }, [chosenFilters]);

  const handleFilterChange = (filters: IFilters) => {
    console.log({ filters });
    setTempFilters(filters);
  };

  const handleApplyFilters = () => {
    console.log({ submit: true, chosenFilters });

    setChosenFilters(tempFilters);
    localStorage.setItem("appliedFilters", JSON.stringify(tempFilters));

    setSidebar({
      title: "",
      isVisible: false,
      component: "",
    });
  };

  const handleDownloadData = async () => {
    console.log("download data");
    try {
      const res = await axios.get(`${API_URL}/api/orders/download`, {
        params: {
          ...tempFilters,
          area: user?.alias,
        },
        responseType: "blob",
      });

      // const fileName = res.headers["Content-Disposition"].split("filename=")[1];

      const navigator: any = window.navigator;

      if (navigator && navigator.msSaveOrOpenBlob) {
        // IE variant
        navigator.msSaveOrOpenBlob(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          "report.xlsx"
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute("download", res.headers["Content-Disposition"].split("filename=")[1]);
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
      }

      console.log({ data: res.data });
    } catch (error) {
      console.log({ error });
    }
  };

  const handleDownloadUsersData = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/api/orders/download-customer-info`,
        {
          params: {
            area: user?.alias,
          },
          responseType: "blob",
        }
      );

      // const fileName = res.headers["Content-Disposition"].split("filename=")[1];

      const navigator: any = window.navigator;

      if (navigator && navigator.msSaveOrOpenBlob) {
        // IE variant
        navigator.msSaveOrOpenBlob(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }),
          "report.xlsx"
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute("download", res.headers["Content-Disposition"].split("filename=")[1]);
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
      }

      console.log({ data: res.data });
    } catch (error) {
      console.log({ error });
    }
  };

  const toggleFilters = () => {
    setSidebar({
      title: "Filters",
      isVisible: true,
      component: "FiltersMenu",
    });

    setFiltersMenuMode(FiltersMenuModes.Filters);
  };

  const toggleDownloadData = () => {
    setSidebar({
      title: "Download Data",
      isVisible: true,
      component: "FiltersMenu",
    });

    setFiltersMenuMode(FiltersMenuModes.Download);
  };

  const submitSearch = (search: string) => {
    const fuse = new Fuse(initialBookings, {
      keys: ["bookingId", "user.firstname", "user.phonenumber", "keyNumber"],
    });

    if (!search) {
      setBookings(initialBookings);
      return;
    }

    const result = fuse.search(search);
    const filteredBookings = result.map((x) => x.item);
    setBookings(filteredBookings);
  };

  console.log({ img: user?.brandImage });

  return (
    <div className="pb-16 mt-4">
      <div>
        {user?.brandImage ? (
          <img
            src={user?.brandImage}
            alt={user?.fullname}
            className="w-28 md:w-[8rem] self-center xl:self-start"
          />
        ) : (
          <h2 className="text-xl text-center md:text-2xl font-semibold">
            {user?.fullname}
          </h2>
        )}
      </div>
      <div className="mb-10">
        <div className="mt-6">
          <p className="text-sm md:text-base text-center mb-3 text-gray-500">
            Results for {chosenFilters.today && "Today"}
            {chosenFilters.yesterday && "Yesterday"}
            {chosenFilters.startDate &&
              !chosenFilters.endDate &&
              `${chosenFilters.startDate} to Today`}
            {!chosenFilters.startDate &&
              chosenFilters.endDate &&
              `${chosenFilters.endDate}`}
            {chosenFilters.startDate &&
              chosenFilters.endDate &&
              `${chosenFilters.startDate} to ${chosenFilters.endDate}`}
          </p>
          <Stats chosenFilters={chosenFilters} />
        </div>
      </div>

      <FiltersNav
        toggleDownloadData={toggleDownloadData}
        submitSearch={submitSearch}
        toggleFilters={toggleFilters}
      />

      {isFetching && (
        <div className="mt-10">
          <h1 className="text-center">Loading...</h1>
        </div>
      )}

      {!isFetching && bookings.length > 0 && !error.hasError && (
        <BookingsList bookings={bookings} />
      )}

      {error.hasError && (
        <div className="mt-10">
          <h1 className="text-center">{error.message}</h1>
        </div>
      )}

      <RightSidebar
        title={sidebar.title}
        isOpen={sidebar.isVisible}
        onClose={() => {
          setSidebar({
            title: "",
            isVisible: false,
            component: "",
          });
          setTempFilters(chosenFilters);
        }}
      >
        {sidebar.component === "FiltersMenu" && (
          <FiltersMenu
            mode={filtersMenuMode}
            filters={tempFilters}
            handleFilterChange={handleFilterChange}
            handleDownloadData={handleDownloadData}
            handleDownloadUsersData={handleDownloadUsersData}
            handleApplyFilters={handleApplyFilters}
          />
        )}
      </RightSidebar>
      {/* <Modal
				title="Sort By"
				onClose={() => setShowSorts(false)}
				isOpen={showSorts}
			>
				<ul>
					{SORT_OPTIONS.map((option) => (
						<li
							className="py-2 text-center text-lg hover:bg-gray-100 rounded-md cursor-pointer"
							key={option.value}
							onClick={() => {
								const newArr = bookings.slice();
								newArr.sort((a, b) => {
									if (option.value === "NEWEST_FIRST") {
										return b.createdAt < a.createdAt;
									} else if (option.value === "OLDEST_FIRST") {
										return a.createdAt < b.createdAt;
									} else {
										return a.createdAt === b.createdAt;
									}
								});

								setBookings(newArr);
								setSortBy(option.value);
								setShowSorts(false);
							}}
						>
							{option.name}
						</li>
					))}
				</ul>
			</Modal> */}
    </div>
  );
};

export default Home;

import React from "react";
import BookingCard from "../BookingCard/BookingCard";
import { Booking } from "../BookingCard/types";

type Props = {
	bookings: Booking[];
};

const BookingsList: React.FC<Props> = ({ bookings }) => {
	return (
		<div className="mt-5">
			<div className="grid md:grid-cols-2 gap-4">
				{bookings.map((booking: Booking, i: number) => (
					<BookingCard booking={booking} key={booking._id} />
				))}
			</div>
		</div>
	);
};

export default BookingsList;

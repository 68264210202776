import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiHash } from "react-icons/fi";
import { BiRupee } from "react-icons/bi";
import { BsBoxArrowInRight } from "react-icons/bs";
import { useAppSelector } from "../../store/hooks";
import { API_URL } from "../../constants";
import { IStat } from "./types";
import { toast } from "react-toastify";

type StatsResponse = {
	stats: IStat[];
};

type Props = {
	chosenFilters: object;
};

const colorShades = [
	{
		bg: { 100: "bg-purple-100", 300: "bg-purple-300" },
		border: "border-purple-300",
	},
	{
		bg: { 100: "bg-cyan-100", 300: "bg-cyan-300" },
		border: "border-cyan-300",
	},
	{
		bg: { 100: "bg-lime-100", 300: "bg-lime-300" },
		border: "border-lime-300",
	},
];
const icons: any = {
	PRICE: <BiRupee />,
	NUMBER: <FiHash />,
	DELIVERY: <BsBoxArrowInRight />,
};

const Stats: React.FC<Props> = ({ chosenFilters }) => {
	const { user } = useAppSelector((state) => state.auth);
	const [stats, setStats] = useState<IStat[]>([]);

	useEffect(() => {
		const fetchStats = async () => {
			try {
				const response = await axios.get<StatsResponse>(
					`${API_URL}/api/partners/${user?._id}/stats`,
					{
						params: chosenFilters,
					}
				);

				console.log({
					data: response.data,
				});

				setStats(response.data.stats);
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						toast.error(error.response.data.error);
					}
				} else {
					console.log({ error });
					toast.error(JSON.stringify(error));
				}
			}
		};

		fetchStats();
	}, [chosenFilters, user?._id]);

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 lg:flex lg:flex-row lg:justify-center lg:flex-wrap gap-4">
			{stats.map((stat, i) => (
				<div
					key={i}
					className={`flex items-stretch rounded-xl w-full lg:w-fit pl-4 md:pl-6 pr-6 md:pr-8 py-4  ${
						colorShades[i % colorShades.length].bg[100]
					}`}
				>
					<div className="flex items-center">
						<div
							className={`text-2xl md:text-3xl mr-3 sm:mr-5 p-3 rounded-xl shadow-lg ${
								colorShades[i % colorShades.length].bg[300]
							}`}
						>
							{icons[stat.type]}
						</div>
						<div>
							<p className="text-xl sm:text-2xl mb-1 font-semibold">{stat.value}</p>
							<h2 className="text-sm sm:text-base text-gray-600">{stat.title}</h2>
						</div>
					</div>
					{stat?.extras && (
						<div
							className={`flex flex-col justify-around ml-5 pl-4 text-sm border-l-2 ${
								colorShades[i % colorShades.length].border
							}`}
						>
							{stat?.extras?.map((extra) => (
								<p className="text-gray-700">
									{extra.title}: {extra.value}
								</p>
							))}
						</div>
					)}
				</div>
			))}
			{/* <div className="flex items-center rounded-xl bg-lime-100 pl-6 pr-8 py-4"> */}
			{/* <div className="text-3xl mr-5 p-3 rounded-xl shadow-lg bg-lime-300">
					<AiOutlineDollar />
				</div> */}
			{/* <div>
					<p className="text-2xl mb-1 font-semibold">22</p>
					<h2 className="text-gray-600">Delivery Requests</h2>
				</div> */}
			{/* </div> */}
		</div>
	);
};

export default Stats;

import React from "react";
import { IoClose } from "react-icons/io5";

type Props = {
	title: string;
	isOpen: boolean;
	onClose: () => void;
};

const RightSidebar: React.FC<Props> = ({ children, title, isOpen, onClose }) => {
	return (
		<div
			className={`fixed inset-0 z-0 transition-opacity ease-in-out duration-500 ${
				isOpen ? "" : "translate-x-full opacity-0"
			}`}
		>
			<div className="bg-gray-900 bg-opacity-50 opacity-100 absolute inset-0 z-0" onClick={onClose} />
			<div
				className={`fixed w-full max-w-lg h-screen right-0 top-0 pointer-events-auto z-90 bg-white pb-10 transition-all duration-500 ease-in-out ${
					isOpen ? "" : "translate-x-full"
				}`}
			>
				<header className="flex justify-between mb-4 px-3 py-4 border-b">
					<h3 className="text-xl">{title}</h3>
					<button className="text-2xl cursor-pointer" onClick={onClose}>
						<IoClose />
					</button>
				</header>
				<div className="px-3">{children}</div>
			</div>
		</div>
	);
};

export default RightSidebar;

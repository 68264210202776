import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import { useAppDispatch } from "../../store/hooks";
import { loginUser } from "../../store/slices/authSlice";
import { API_URL } from "../../constants";
import { setAuthToken } from "../../utils";
import IUser from "../../types/user.type";

type LoginResponse = {
	message: string;
	accessToken: string;
	user: IUser;
};

const Login: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [formValues, setFormValues] = useState({
		username: "",
		password: "",
	});

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			setIsSubmitting(true);
			const response = await axios.post<LoginResponse>(
				`${API_URL}/api/partners/login`,
				{
					alias: formValues.username,
					password: formValues.password,
				},
				{
					params: {
						type: "admin",
					},
				}
			);

			const data = await response.data;

			localStorage.setItem("jwtToken", data.accessToken);
			setAuthToken(data.accessToken);

			dispatch(loginUser(data.user));

			toast.success("Logged in successfully");
			setIsSubmitting(false);

			navigate("/home");
		} catch (error) {
			if (axios.isAxiosError(error)) {
				if (error.response) {
					toast.error(error.response.data.error);
				}
			} else {
				console.log({ error });
				toast.error(JSON.stringify(error));
			}
			setIsSubmitting(false);
		}
	};

	return (
		<div className="mt-6">
			<h1 className="text-center text-2xl md:text-3xl pt-4 mb-10">
				Companion Admin Panel
			</h1>
			<form
				onSubmit={handleSubmit}
				className="flex flex-col items-center w-full max-w-lg mx-auto px-3"
			>
				<div className="w-full mb-4">
					<label htmlFor="username" className="block mb-2">
						Username
					</label>
					<input
						type="text"
						name="username"
						id="username"
						placeholder="Username"
						onChange={handleChange}
						className="border border-gray-300 rounded px-4 py-2 w-full"
					/>
				</div>
				<div className="w-full">
					<label htmlFor="password" className="block mb-2">
						Password
					</label>
					<input
						type="password"
						name="password"
						id="password"
						placeholder="Password"
						onChange={handleChange}
						className="border border-gray-300 rounded px-4 py-2 w-full"
					/>
				</div>
				<button
					disabled={isSubmitting}
					className={`flex items-center rounded mt-8 bg-blue-600 text-md text-white px-6 py-2 ${
						isSubmitting ? "disabled:opacity-70" : ""
					}`}
					type="submit"
				>
					{isSubmitting ? (
						<>
							<span className="inline-block mr-2 w-5 h-5 border-2 rounded-full border-white/50 border-l-white animate-spin" />
							<span>Logging In...</span>
						</>
					) : (
						<span className="px-2">Login</span>
					)}
				</button>
			</form>
		</div>
	);
};

export default Login;

import React, { useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { OutlinedButton } from "../Buttons";

type Props = {
  submitSearch: (search: string) => void;
  toggleFilters: () => void;
  toggleDownloadData: () => void;
};

const FiltersNav: React.FC<Props> = ({
  toggleFilters,
  submitSearch,
  toggleDownloadData,
}) => {
  const [search, setSearch] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitSearch(search);
  };

  return (
    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mt-6 mb-6">
      <div className="flex mb-4 sm:mb-0">
        <OutlinedButton
          className="mr-2 whitespace-nowrap text-xs sm:text-base"
          onClick={toggleFilters}
        >
          Filter By
        </OutlinedButton>
        <OutlinedButton
          className="mr-2 whitespace-nowrap text-xs sm:text-base"
          onClick={toggleDownloadData}
        >
          Download Data
        </OutlinedButton>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-stretch">
          <input
            type="text"
            placeholder="Key, Name or Number"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="border border-gray-400 w-full border-r-0 flex-1 px-3 py-2 rounded rounded-tr-none rounded-br-none"
          />
          <button
            type="submit"
            className="bg-blue-600 border border-blue-600 text-xl text-white px-3 rounded rounded-tl-none rounded-bl-none"
          >
            <RiSearchLine />
          </button>
        </div>
        {/* <OutlinedButtonSm onClick={toggleSorts}>Sort by</OutlinedButtonSm> */}
      </form>
    </div>
  );
};

export default FiltersNav;

import axios from "axios";

export const setAuthToken = (token: string | null) => {
	if (token) {
		// apply authorization token to every request if logged in
		axios.defaults.headers.common["Authorization"] = token;
	} else {
		// delete auth header
		delete axios.defaults.headers.common["Authorization"];
	}
};

import React from "react";
import { Booking } from "./types";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import { BsCheck2Circle } from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";
import { RiVipCrownFill } from "react-icons/ri";

type Props = {
  booking: Booking;
};

const BookingCard: React.FC<Props> = ({ booking }) => {
  const navigate = useNavigate();

  return (
    <div className="border rounded">
      <header className="flex items-center justify-between px-4 py-3 bg-gray-50">
        <p className="text-sm">Booking Id: {booking.bookingId}</p>
        <span className="py-1 px-3 border rounded-full bg-white text-xs">
          {booking.status}
        </span>
      </header>
      <div className="px-4 py-3 flex items-stretch">
        <div className="flex-1 mr-2">
          {booking?.bookingMethod === "TICKET_BOOKING" &&
          booking?.user?._id === "61d3db83457f59000497cdcc" ? (
            <p className="mb-2">
              Ticket: <span>{booking.ticketNumber}</span>
            </p>
          ) : (
            <p className="mb-2">
              {booking.user.firstname} ({booking.user.phonenumber})
            </p>
          )}
          {!!booking.vehicleNumber && booking.vehicleNumber != "undefined" && (
            <p className="mb-2 text-sm">{booking.vehicleNumber}</p>
          )}
          <p className="text-gray-500 text-sm">
            {format(new Date(booking.createdAt), "hh:mm aa | dd-MM-yy")}
          </p>
          <div className="mt-4">
            {booking.paymentStatus ? (
              <div className="flex items-center mb-1">
                {booking.paymentType === "EXEMPTED" ? (
                  <RiVipCrownFill color="#2563eb" />
                ) : (
                  <BiRupee color="#c026d3" />
                )}
                {/* <BiRupee color="#c026d3" /> */}
                <span
                  className={`ml-2 text-xs ${
                    booking.paymentType === "EXEMPTED"
                      ? "text-blue-600"
                      : "text-fuchsia-600"
                  }`}
                >
                  {booking.paymentType === "EXEMPTED"
                    ? "Exempted"
                    : "Payment Done"}
                </span>
              </div>
            ) : (
              <div className="flex items-center mb-1">
                <GrStatusInfo color="#44403c" />
                <span className="ml-1 text-stone-700 text-xs">
                  Not Paid
                </span>{" "}
              </div>
            )}

            {booking.isMarkedAs === "COMPLETED" && (
              <div className="flex items-center ">
                <BsCheck2Circle color="#16a34a" />
                <span className="ml-2 text-green-600 text-xs">
                  Marked Completed
                </span>
              </div>
            )}

            {booking.isMarkedAs === "CANCELLED" && (
              <div className="flex items-center ">
                <AiOutlineCloseCircle color="#ef4444" />
                <span className="ml-2 text-red-500 text-xs">
                  Marked Cancelled
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <p className="text-right text-sm mb-3">
            Key : {booking.keyNumber || "--"}
          </p>
          <button
            className="bg-blue-600 text-white text-sm px-[0.8rem] py-1 rounded"
            onClick={() =>
              navigate(`/bookings/${booking._id}`, {
                state: {
                  booking,
                },
              })
            }
          >
            View More
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingCard;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IUser from "../../types/user.type";

type AuthState = {
	isAuthenticated: boolean;
	user: IUser | null;
};

const initialState: AuthState = {
	user: null,
	isAuthenticated: false,
};

export const authSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		loginUser: (state, action: PayloadAction<IUser>) => {
			state.user = action.payload;
			state.isAuthenticated = true;
		},
		logoutUser: (state) => {
			state.user = null;
			state.isAuthenticated = false;
		},
	},
});

// each case under reducers becomes an action
export const { loginUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;

import React from "react";
import splashLogo from "../../assets/splash-logo.png";

const SplashScreen: React.FC = () => {
	return (
		<div className="flex items-center justify-center flex-col fixed inset-0 pointer-events-none">
			<img src={splashLogo} alt="ParkMate" className="mb-8 w-28 md:w-36" />
			<div className="w-10 h-10 border-4 rounded-full border-gray-300 border-l-primary animate-spin" />
		</div>
	);
};

export default SplashScreen;

import React, { useState } from "react";
import { FiltersMenuModes } from "../../Pages/Home/Home";

type IFilters = {
  today?: boolean;
  yesterday?: boolean;
  startDate?: string;
  endDate?: string;
  forDate?: string;
};

type Props = {
  filters: IFilters;
  handleFilterChange: (filters: IFilters) => void;
  handleApplyFilters: () => void;
  handleDownloadData: () => void;
  handleDownloadUsersData: () => void;
  mode: FiltersMenuModes;
};

const getMaxDate = () => {
  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const currentDate = now.getDate();

  const endDate = `${now.getFullYear()}-${
    currentMonth < 10 ? "0" + currentMonth : currentMonth
  }-${currentDate < 10 ? "0" + currentDate : currentDate}`;

  return endDate;
};

const FiltersMenu: React.FC<Props> = ({
  filters,
  handleFilterChange,
  handleApplyFilters,
  handleDownloadData,
  handleDownloadUsersData,
  mode,
}) => {
  console.log({ filtersMenu: filters });
  return (
    <div>
      <h3 className="font-semibold mb-3 text-xl">Date</h3>
      <div className="mb-2 flex">
        <button
          className={`border px-4 py-1 block rounded mr-2 ${
            filters.today ? "border-blue-600 text-blue-600" : ""
          }`}
          onClick={() =>
            handleFilterChange({
              ...filters,
              today: !filters.today,
              yesterday: false,
              startDate: "",
              endDate: "",
            })
          }
        >
          Today
        </button>
        <button
          className={`border px-4 py-1 block rounded mr-2 ${
            filters.yesterday ? "border-blue-600 text-blue-600" : ""
          }`}
          onClick={() =>
            handleFilterChange({
              ...filters,
              yesterday: !filters.yesterday,
              today: false,
              startDate: "",
              endDate: "",
            })
          }
        >
          Yesterday
        </button>
      </div>
      <div className="mt-4 ml-2">
        <div className="flex items-center mb-2">
          <p className="mr-3 w-30">Specific Date</p>
          <input
            min="2022-02-14"
            max={getMaxDate()}
            placeholder="Select Date"
            type="date"
            className={`border p-2 rounded ${
              filters.startDate ? "border-blue-600 text-blue-600" : ""
            }`}
            value={filters.startDate || ""}
            onChange={(e) => {
              console.log({ startDate: e.target.value });
              handleFilterChange({
                ...filters,
                today: false,
                yesterday: false,
                startDate: e.target.value,
                endDate: e.target.value,
              });
            }}
          />
        </div>
        {/* <div className="flex items-center">
					<p className="mr-3 w-10">To</p>
					<input
						min="2022-02-14"
						max={getMaxDate()}
						placeholder="Select Date"
						value={filters.endDate || ""}
						onChange={(e) => {
							console.log({ endDate: e.target.value });
							handleFilterChange({
								...filters,
								today: false,
								yesterday: false,
								endDate: e.target.value,
							});
						}}
						type="date"
						className={`border p-2 rounded ${
							filters.endDate ? "border-blue-600 text-blue-600" : ""
						}`}
					/>
				</div> */}
      </div>
      <hr />
      <div className="mt-4 ml-2">
        <div className="flex items-center mb-2">
          <p className="mr-3 w-10">From</p>
          <input
            min="2022-02-14"
            max={getMaxDate()}
            placeholder="Select Date"
            value={filters.startDate || ""}
            onChange={(e) => {
              console.log({ startDate: e.target.value });
              handleFilterChange({
                ...filters,
                today: false,
                yesterday: false,
                startDate: e.target.value,
              });
            }}
            type="date"
            className={`border p-2 rounded ${
              filters.startDate ? "border-blue-600 text-blue-600" : ""
            }`}
          />
        </div>
        <div className="flex items-center">
          <p className="mr-3 w-10">To</p>
          <input
            min="2022-02-14"
            max={getMaxDate()}
            placeholder="Select Date"
            value={filters.endDate || ""}
            onChange={(e) => {
              console.log({ endDate: e.target.value });
              handleFilterChange({
                ...filters,
                today: false,
                yesterday: false,
                endDate: e.target.value,
              });
            }}
            type="date"
            className={`border p-2 rounded ${
              filters.endDate ? "border-blue-600 text-blue-600" : ""
            }`}
          />
        </div>
      </div>
      <div className="w-full p-3 mt-10 border-t">
        {mode === FiltersMenuModes.Filters && (
          <button
            onClick={handleApplyFilters}
            className="bg-sky-600 text-white p-2 rounded w-full"
          >
            Apply Filters
          </button>
        )}
        {mode === FiltersMenuModes.Download && (
          <button
            onClick={handleDownloadData}
            className="bg-sky-600 text-white p-2 rounded w-full"
          >
            Download Data
          </button>
        )}
        {mode === FiltersMenuModes.Download && (
          <button
            onClick={handleDownloadUsersData}
            className="mt-4 bg-white border text-black p-2 rounded w-full"
          >
            Download Customer Info
          </button>
        )}
      </div>
    </div>
  );
};

export default FiltersMenu;

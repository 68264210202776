import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import dayjs from "dayjs";
import { Button } from "../../components/Buttons";
import { FiChevronLeft } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { MAIN_API } from "../../constants";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function resolve(path: string, obj: object, separator = ".") {
  const properties: any[] = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
}

type TableProps = {
  title: string;
  fields: {
    title: string;
    field: string;
    isImage?: boolean;
    isBool?: boolean;
    isDate?: boolean;
    isCustom?: boolean;
    customComponent?: React.ReactNode;
    shouldRenderRow?: boolean;
    dontRenderNull?: boolean;
    values?: any;
  }[];
  booking: object;
};
const Table: React.FC<TableProps> = ({ title, fields, booking }) => {
  return (
    <div className="border rounded-md overflow-hidden border-gray-300 w-full mb-6">
      <header className="border-b border-gray-300 bg-gray-50 px-3 py-2 text-lg">
        <h3 className="text-center font-semibold">{title}</h3>
      </header>
      <div>
        {fields.map((item) => {
          const fieldValue = resolve(item.field, booking);

          if (item.isImage) {
            if (item.dontRenderNull && fieldValue) {
              return (
                <div className="flex items-center">
                  <p className="w-1/2 py-2 border-r px-4 min-h-10 flex items-center">
                    <span>{item.title}</span>
                  </p>
                  <div className="px-4 py-2 border-b w-1/2 min-h-10 flex items-center">
                    {fieldValue && (
                      <img
                        style={{ height: "13rem", objectFit: "cover" }}
                        src={fieldValue}
                        alt={item.field}
                      />
                    )}
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          }

          if (item.isBool) {
            return (
              <div className="flex items-center">
                <p className="w-1/2 border-r border-b px-4 h-10 flex items-center">
                  <span>{item.title}</span>
                </p>
                <div className="px-4 border-b w-1/2 h-10 flex items-center">
                  <span>{fieldValue ? item.values[1] : item.values[0]}</span>
                </div>
              </div>
            );
          }

          if (item.isDate) {
            return (
              <div className="flex items-center">
                <p className="w-1/2 border-r border-b px-4 h-10 flex items-center">
                  <span>{item.title}</span>
                </p>
                <div className="px-4 h-10 border-b w-1/2 flex items-center">
                  <span>
                    {fieldValue === ""
                      ? "--"
                      : format(new Date(fieldValue), "hh:mm aa | dd-MM-yy")}
                  </span>
                </div>
              </div>
            );
          }

          if (item.isCustom) {
            if (item.shouldRenderRow !== undefined && item.shouldRenderRow) {
              return (
                <div className="flex items-stretch">
                  <p className="w-1/2 border-r border-b px-4 min-h-10 flex items-center">
                    <span>{item.title}</span>
                  </p>
                  <div className="px-4 min-h-10 border-b w-1/2 flex items-center">
                    {item.customComponent}
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          }

          return (
            <div className="flex items-stretch">
              <p className="w-1/2 border-r border-b px-4 h-10 flex items-center">
                <span>{item.title}</span>
              </p>
              <div className="px-4 h-10 border-b w-1/2 flex items-center">
                <span>{fieldValue === "" ? "--" : fieldValue}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type LocationState = {
  booking: any;
};

const BookingDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { booking } = location.state as LocationState;
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedImgSet, setSelectedImgSet] = useState("CAR_IMAGE");
  const [imageInfo, setImageInfo] = useState({
    first: "",
    second: "",
  });
  const [bookingImages, setBookingImages] = useState<any>({
    CAR_IMAGE: [],
    TICKET_IMAGE: [],
    KEY_IMAGE: [],
    CAR_NUMBER_IMAGE: [],
  });

  const fetchBookingImages = async (id: string) => {
    const { data } = await axios.get(`${MAIN_API}/api/order-images/`, {
      params: { orderId: id },
    });

    console.log({ data });
    const imagesMap: any = {
      CAR_IMAGE: [],
      TICKET_IMAGE: [],
      KEY_IMAGE: [],
      CAR_NUMBER_IMAGE: [],
    };

    if (data) {
      data.orderImages.forEach((image: any) => {
        imagesMap[image.type].push(image);
      });

      setBookingImages(imagesMap);
    }
  };

  useEffect(() => {
    if (booking._id) {
      fetchBookingImages(booking._id);
    }
  }, [booking._id]);

  useEffect(() => {
    console.log({ booking });
  }, [booking._id]);

  const selectedValetsList = () => {
    console.log({ selectedValets: booking.selectedValets });

    return (
      <ul className="list-disc ml-3 py-2">
        {booking?.selectedValets?.map((valet: any) => (
          <li>{`${valet.firstname} ${valet.lastname}`}</li>
        ))}
      </ul>
    );
  };

  const bookingDetailsFields = [
    { title: "ID", field: "bookingId" },
    { title: "Ticket Number", field: "ticketNumber" },
    { title: "Booked From", field: "source" },
    { title: "Key ID", field: "keyNumber" },
    { title: "Booking Method", field: "bookingMethod" },
    { title: "Order Status", field: "status" },
    { title: "Car Number", field: "vehicleNumber" },
    { title: "Booked At", field: "createdAt", isDate: true },
    {
      title: "Selected Valets",
      field: "selectedValets",
      isCustom: true,
      shouldRenderRow: true,
      customComponent: selectedValetsList(),
    },
  ];

  const customerDetailsFields = [
    { title: "Customer Name", field: "user.firstname" },
    { title: "Customer Number", field: "user.phonenumber" },
  ];

  const vehicleFields = [
    { title: "Vehicle Number", field: "vehicle.plateInfo.plateNumber" },
    { title: "Vehicle Type", field: "vehicle.vehicleInfo.type" },
    { title: "Vehicle Model", field: "vehicle.modelMake.model" },
    { title: "Vehicle Make", field: "vehicle.modelMake.make" },
    { title: "Vehicle Colour", field: "vehicle.color.color" },
    { title: "Vehicle Orientation", field: "vehicle.orientation.orientation" },
  ]

  const paymentDetailsFields = [
    {
      title: "Payment Status",
      field: "paymentStatus",
      isBool: true,
      values: ["Pending", "Paid"],
    },
    { title: "Payment Mode", field: "paymentType" },
    { title: "Amount", field: "parkingCharges" },
    { title: "Waiting Charges", field: "overCharges" },
  ];

  const pickupDetailsFields = [
    { title: "Pickup Valet", field: "pickupValet.firstname" },
    { title: "Valet Number", field: "pickupValet.phonenumber" },
    { title: "Pickup Address", field: "pickupAddress" },
  ];

  const deliveryDetailsFields = [
    { title: "Delivery Valet", field: "deliveryValet.firstname" },
    { title: "Valet Number", field: "deliveryValet.phonenumber" },
    { title: "Delivery Address", field: "deliveryAddress" },
  ];

  const parkingDetailsFields = [
    { title: "Parking Name", field: "parkingName" },
    {
      title: "Parking Image 1",
      field: "parkingTicketImage",
      isImage: true,
      dontRenderNull: true,
    },
    {
      title: "Parking Image 2",
      field: "parkingCarImage",
      isImage: true,
      dontRenderNull: true,
    },
    {
      title: "Car Images",
      field: "",
      isCustom: true,
      customComponent: [
        <button
          onClick={() => {
            setIsLightboxOpen(true);
            setSelectedImgSet("CAR_IMAGE");
            setImageInfo({
              first: bookingImages?.CAR_IMAGE[0]?.status,
              second: bookingImages?.CAR_IMAGE[0]?.createdAt,
            });
          }}
          className="border text-sm border-blue-500 text-blue-500 rounded px-4 h-8 my-2"
        >
          View Images
        </button>,
      ],
      shouldRenderRow: bookingImages.CAR_IMAGE.length > 0,
    },
    {
      title: "Ticket Images",
      field: "",
      isCustom: true,
      customComponent: [
        <button
          onClick={() => {
            setIsLightboxOpen(true);
            setSelectedImgSet("TICKET_IMAGE");
            setImageInfo({
              first: bookingImages?.TICKET_IMAGE[0]?.status,
              second: bookingImages?.TICKET_IMAGE[0]?.createdAt,
            });
          }}
          className="border text-sm border-blue-500 text-blue-500 rounded px-4 h-8 my-2"
        >
          View Images
        </button>,
      ],
      shouldRenderRow: bookingImages.TICKET_IMAGE.length > 0,
    },
    {
      title: "Key Images",
      field: "",
      isCustom: true,
      customComponent: [
        <button
          onClick={() => {
            setIsLightboxOpen(true);
            setSelectedImgSet("KEY_IMAGE");
            setImageInfo({
              first: bookingImages?.KEY_IMAGE[0]?.status,
              second: bookingImages?.KEY_IMAGE[0]?.createdAt,
            });
          }}
          className="border text-sm border-blue-500 text-blue-500 rounded px-4 h-8 my-2"
        >
          View Images
        </button>,
      ],
      shouldRenderRow: bookingImages.KEY_IMAGE.length > 0,
    },
    {
      title: "Number Plate Images",
      field: "",
      isCustom: true,
      customComponent: [
        <button
          onClick={() => {
            setIsLightboxOpen(true);
            setSelectedImgSet("CAR_NUMBER_IMAGE");
            setImageInfo({
              first: bookingImages.CAR_NUMBER_IMAGE[0]?.status,
              second: bookingImages?.CAR_NUMBER_IMAGE[0]?.createdAt,
            });
          }}
          className="border text-sm border-blue-500 text-blue-500 rounded px-4 h-8 my-2"
        >
          View Images
        </button>,
      ],
      shouldRenderRow: bookingImages.CAR_NUMBER_IMAGE.length > 0,
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleBack = () => navigate(-1);

  console.log({ imageInfo });

  return (
    <div className="mt-6">
      {/* {(booking.parkingCarImage || booking.parkingTicketImage) && (
				<SRLWrapper
					callbacks={{
						onLightboxClosed: () => {
							// setIsLightboxOpen(false);
						},
					}}
				>
					<div className="hidden">
						{booking.parkingCarImage ? (
							<img src={booking.parkingCarImage} alt="Car Image" />
						) : (
							<></>
						)}
						{booking.parkingTicketImage ? (
							<img src={booking.parkingTicketImage} alt="Ticket Image" />
						) : (
							<></>
						)}
						{bookingImages
							.filter((image) => selectedImgSet === image.type)
							.map((image) => (
								<img
									src={image.url}
									key={image._id}
									alt={
										image.status + " " + dayjs(image.createdAt).format("hh:mm A DD/MM/YY")
									}
								/>
							))}
					</div>
				</SRLWrapper>
			)} */}
      {isLightboxOpen && (
        <div className="fixed inset-0 w-screen h-screen bg-gray-900/90 flex align-center">
          <div className="absolute bottom-2 left-0 w-full flex justify-between">
            <div className="text-xs text-white ml-4">
              <p>{imageInfo.first}</p>
              <p>{dayjs(imageInfo.second).format("hh:mm A DD/MM/YY")}</p>
            </div>
            <button
              onClick={() => setIsLightboxOpen(false)}
              className="text-2xl md:text-3xl p-1 mr-4 mt-auto"
            >
              <IoClose color="#fff" />
            </button>
          </div>
          <Carousel
            className="mx-auto md:w-2/3"
            width="100%"
            swipeable
            infiniteLoop
            showArrows
            autoFocus
            showIndicators={false}
            showThumbs
            thumbWidth={40}
            onChange={(i, item: any) => {
              setImageInfo({
                first: item.props["data-status"],
                second: item.props["data-time"],
              });
            }}
          >
            {bookingImages[selectedImgSet]?.map((image: any) => (
              <div
                className="w-full h-[80vh]"
                data-status={image.status}
                data-time={image.createdAt}
              >
                <img
                  src={image.url}
                  alt={image.title}
                  className="object-contain w-full h-full"
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}

      <header className="mb-6">
        <Button sm onClick={handleBack} className="flex items-center pl-1">
          <FiChevronLeft className="text-lg" />{" "}
          <span className="ml-1">Go Back</span>
        </Button>
      </header>
      <div>
        <Table
          title="Booking Details"
          fields={bookingDetailsFields}
          booking={booking}
        />
        <Table
          title="Customer Details"
          fields={customerDetailsFields}
          booking={booking}
        />
        {booking.vehicle ? <Table
          title="Vehicle Details"
          fields={vehicleFields}
          booking={booking}
        /> : ""}
        <Table
          title="Payment Details"
          fields={paymentDetailsFields}
          booking={booking}
        />
        <Table
          title="Pickup Details"
          fields={pickupDetailsFields}
          booking={booking}
        />
        <Table
          title="Delivery Details"
          fields={deliveryDetailsFields}
          booking={booking}
        />
        <Table
          title="Parking Details"
          fields={parkingDetailsFields}
          booking={booking}
        />
      </div>
    </div>
  );
};

export default BookingDetails;

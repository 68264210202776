import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
	const auth = useAppSelector((state) => state.auth);
	return auth.isAuthenticated ? children : <Navigate to="/" />;
};

export default PrivateRoute;

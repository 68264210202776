import React from "react";

type Props = {
	type?: "button" | "submit" | "reset" | undefined;
	className?: string;
	base?: boolean;
	sm?: boolean;
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button: React.FC<Props> = ({
	children,
	onClick,
	className,
	base,
	sm,
	type = "button",
}) => {
	let padding = "px-4 py-2";
	let fontSize = "text-md";

	if (base) {
		padding = "px-3 md:px-4 py-1";
		fontSize = "text-sm md:text-base";
	}

	if (sm) {
		padding = "px-3 py-1";
		fontSize = "text-sm";
	}

	return (
		<button
			className={`rounded bg-blue-600 text-white ${padding} ${fontSize} ${className}`}
			type={type}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default Button;

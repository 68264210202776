import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import jwt_decode from "jwt-decode";
import store from "./store";
import { useAppDispatch } from "./store/hooks";
import { loginUser, logoutUser } from "./store/slices/authSlice";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import BookingDetails from "./Pages/BookingDetails/BookingDetails";
import Layout from "./components/Layout/Layout";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./commons/PrivateRoute";
import { setAuthToken } from "./utils";
import { API_URL } from "./constants";
import IUser from "./types/user.type";
import { IDecodedJWT } from "./types/jwt.type";

type PartnerResponse = {
	user: IUser;
};

const App: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [timer, setTimer] = useState<any>(-1);

	useEffect(() => {
		localStorage.removeItem("appliedFilters");

		const handleUserLogin = async (userId: string) => {
			try {
				const response = await axios.get<PartnerResponse>(`${API_URL}/api/partners/${userId}`);
				const data = await response.data;
				dispatch(loginUser(data.user));
				navigate("/home");

				clearTimeout(timer);
				setTimer(-1);
				const t = setTimeout(() => {
					setIsLoading(false);
				}, 1000);
				setTimer(t);
			} catch (error) {
				if (axios.isAxiosError(error)) {
					if (error.response) {
						toast.error(error.response.data.error);
					}
				} else {
					console.log({ error });
					toast.error(JSON.stringify(error));
				}
				setIsLoading(false);
			}
		};

		if (localStorage.jwtToken) {
			setIsLoading(true);
			const token = localStorage.jwtToken;
			setAuthToken(token);

			const decoded: IDecodedJWT = jwt_decode(token);

			console.log({ decoded });

			const currentTime = Date.now() / 1000;
			if (decoded.exp < currentTime) {
				localStorage.removeItem("jwtToken");
				setAuthToken(null);
				dispatch(logoutUser());

				navigate("/");
				setIsLoading(false);
			} else {
				handleUserLogin(decoded._id);
			}
		} else {
			setIsLoading(false);
		}

		return () => {
			localStorage.removeItem("appliedFilters");
			clearTimeout(timer);
		};
	}, []);

	if (isLoading) return <SplashScreen />;

	return (
		<div>
			<Layout>
				<Routes>
					<Route
						path="/home"
						element={
							<PrivateRoute>
								<Home />
							</PrivateRoute>
						}
					/>
					<Route path="/bookings/:id" element={<BookingDetails />} />
					<Route path="/" element={<Login />} />
				</Routes>
			</Layout>
		</div>
	);
};

function Main() {
	return (
		<Provider store={store}>
			<ToastContainer />
			<Router>
				<App />
			</Router>
		</Provider>
	);
}

export default Main;

import React from "react";
import Container from "../../commons/Container";
import Navbar from "../Navbar/Navbar";

const Layout: React.FC = ({ children }) => {
	return (
		<div>
			<Navbar />
			<main>
				<Container>{children}</Container>
			</main>
		</div>
	);
};

export default Layout;
